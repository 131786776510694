<template>
  <div v-if="showModule">
    <div  class="row" v-for="section in sections" :key="section.title">
      <div class="col-sm-12 mb-2 mt-2">
        <h4 v-if="section.title" class="mb-1 float-left">{{section.title}}</h4>
      </div>
      <div id="list-card" v-for="(card, index) in cards.filter(c => c.parent === section.id)" :key="index" class="col-sm-12 col-md-5 col-lg-5 mb-2">
        <b-card no-body class="card-carrier p-1">
          <div class="container-content-card" style="height: 100px">
            <div class="text-center soft-shown">
              <b-img :src="card.logo" :alt="card.name" :style="{'max-width': card.code === 'RPP' ? '100px' : '130px', 'max-height': '90px', 'margin': 'auto'}"/>
            </div>
            <p class="class-paragragh text-left pl-1 pr-1" > {{card.description}} </p>
            <template>
              <b-form-checkbox class="custom-switch-light" :checked="card.enabled" switch v-model="card.enabled" @change="setSwitch(index, card)"></b-form-checkbox>
            </template>
          </div>
        </b-card>
      </div>
    </div>
    <div class="col-md-12 col-sm-12 mt-1">
      <b-button variant="warning" class="push-right" @click="sendForm" :disabled="loading.buttonSend"> 
        <feather-icon v-if="loading.buttonSend" icon="LoaderIcon" class="spinner"/>
        {{$t('Guardar configuración')}}
      </b-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'carriers',
  props: ['sections', 'carriers', 'align', 'mySession', 'buttonSend'],
  data() {
    return {
      showModule: false,
      loading: {
        buttonSend: false
      }
    }
  },
  watch: {
    carriers () {
      this.cards = this.carriers.carriers
      if (this.cards.findIndex(any => any.enabled !== true) === -1) { 
        this.sections = this.sections.filter(section => section.id === 0)
      } else if ((this.cards.findIndex(any => any.enabled !== false) === -1)) {
        this.sections = this.sections.filter(section => section.id === 1)
      }
      this.showModule = true
    },
    buttonSend () {
      this.loading.buttonSend = this.buttonSend
    }
  },
  mounted() {
    if (this.cards && this.cards.length !== 0) {
      if (this.cards.findIndex(any => any.enabled !== true) === -1) { 
        this.sections = this.sections.filter(section => section.id === 0)
      } else if ((this.cards.findIndex(any => any.enabled !== false) === -1)) {
        this.sections = this.sections.filter(section => section.id === 1)
      }
      this.showModule = true
    }
  },
  methods: {
    setSwitch(index, value) {
      value.parent = value.enabled ? 0 : 1
      const indexCard = this.cards.findIndex(carrier => carrier.code === value.code)
      this.cards.splice(indexCard, 1, value)
    },
    sendForm () {
      this.loading.buttonSend = true
      const payload = { ...this.carriers, carrier_codes: this.cards.filter(card => (card.enabled === true)).map(card => card.code) }
      delete payload.carriers
      this.$emit('emit', payload)
    }
  }
}
</script>
<style lang="scss">
  .class-paragragh {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0rem;
    padding: 0rem 1rem 0rem 1rem;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    line-clamp: 10;
    overflow: hidden;
  }
  .container-content-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .card-carrier {
    background: #F2F5FA;
    border-radius: 16px;
  }
  #list-card .card {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  }
</style>